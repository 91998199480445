import React, { useState } from 'react';
import Header from '../../../../Components/Header/Header';
import Footer from '../../../../Components/Footer/Footer';
import ServiceCard from '../../../../Components/ServiceCard/ServiceCard';
import styles from './servicesall.module.scss';

import ClearCleaning from '../../../../Assets/Images/Logos/ClearServices Logos/ClearCleaning.png';
import ClearContracting from '../../../../Assets/Images/Logos/ClearServices Logos/ClearContracting.png';

import janitorialServiceList from '../../../../Data/ClearCleaning.json';
import contractingServiceList from '../../../../Data/ClearContracting.json';
import clientList from '../../../../Data/Clients.json';
import projectList from '../../../../Data/Projects.json';
import { Helmet } from 'react-helmet-async';

const ServicesAll = () => {
    const [cleaningServices] = useState(janitorialServiceList);
    const [contractingServices] = useState(contractingServiceList);
    const [clients] = useState(clientList);
    const [projects] = useState(projectList);

    let JanitorialServices = cleaningServices.map((service) => {
        return (
            <ServiceCard
                key={service.id}
                id={service.id}
                serviceName={service.serviceName}
                icon={service.icon}
                type={service.type}
                quoteAvailable={service.quoteAvailable}
            />
        );
    });

    let ContractingServices = contractingServices.map((service) => {
        return (
            <ServiceCard
                key={service.id}
                id={service.id}
                serviceName={service.serviceName}
                icon={service.icon}
                type={service.type}
                quoteAvailable={service.quoteAvailable}
            />
        );
    });

    let ClientRender = clients.map((client) => {
        return (
            <img
                key={client.name}
                className={styles.carouselItem}
                src={require(`../../../../Assets/Images/Logos/Client Logos/${client.path}`)}
                alt={client.name}
            />
        );
    });

    let ProjectRender = projects.map((project) => {
        return (
            <div key={project.id}>
                <h4>{project.name}</h4>
                <img
                    src={require(`../../../../Assets/Images/Projects/${project.path}`)}
                    alt={project.name}
                />
            </div>
        );
    });

    return (
        <>
            <Helmet>
                <title>ClearServices - Our Services</title>
                <meta name='description' content='We provide professional contracting and janitorial services with our experienced crew since July 2017.' />
                <link rel='canonical' href='/services-all' />
            </Helmet>
            <Header />
            <div id='Services' className={styles.serviceWrapper}>
                <h1>Services</h1>
                <img src={ClearCleaning} alt='ClearCleaning' />
                <div className={styles.serviceContainer}>{JanitorialServices}</div>
            </div>

            <div className={styles.serviceWrapper}>
                <img src={ClearContracting} alt='ClearContracting' />
                <div className={styles.serviceContainer}>{ContractingServices}</div>
            </div>

            <div id='Clients' className={styles.sectionContainer}>
                <h1>Our Clients</h1>
                <div className={styles.clientCarousel}>
                    <div className={styles.carouselContainer}>{ClientRender}</div>
                </div>
            </div>
            <div className={styles.projects}>{ProjectRender}</div>
            <Footer />
        </>
    );
};

export default ServicesAll;
