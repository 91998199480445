import React, { useState } from 'react';
import Stories from '../../../Components/Stories/Stories';
import Header from '../../../Components/Header/Header';
import Footer from '../../../Components/Footer/Footer';
import ServiceCard from '../../../Components/ServiceCard/ServiceCard';
import styles from './home.module.scss';

import Trushield from '../../../Assets/Images/Icons/Insurance/insurance.png';
import Wsib from '../../../Assets/Images/Icons/Insurance/wsib.png';
import ClearCleaning from '../../../Assets/Images/Logos/ClearServices Logos/ClearCleaning.png';
import ClearContracting from '../../../Assets/Images/Logos/ClearServices Logos/ClearContracting.png';

import janitorialServiceList from '../../../Data/ClearCleaning.json';
import contractingServiceList from '../../../Data/ClearContracting.json';
import clientList from '../../../Data/Clients.json';
import projectList from '../../../Data/Projects.json';
import StoryData from '../../../Data/Gallery.json';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    const [cleaningServices] = useState(janitorialServiceList);
    const [contractingServices] = useState(contractingServiceList);
    const [clients] = useState(clientList);
    const [projects] = useState(projectList);

    let JanitorialServices = cleaningServices.map((service) => {
        return (
            <ServiceCard
                key={service.id}
                id={service.id}
                serviceName={service.serviceName}
                icon={service.icon}
                type={service.type}
                quoteAvailable={service.quoteAvailable}
            />
        );
    });

    let ContractingServices = contractingServices.map((service) => {
        return (
            <ServiceCard
                key={service.id}
                id={service.id}
                serviceName={service.serviceName}
                icon={service.icon}
                type={service.type}
                quoteAvailable={service.quoteAvailable}
            />
        );
    });

    let ClientRender = clients.map((client) => {
        return (
            <img
                key={client.name}
                className={styles.carouselItem}
                src={require(`../../../Assets/Images/Logos/Client Logos/${client.path}`)}
                alt={client.name}
            />
        );
    });

    let ProjectRender = projects.map((project) => {
        return (
            <div key={project.id}>
                <h4>{project.name}</h4>
                <img
                    src={require(`../../../Assets/Images/Projects/${project.path}`)}
                    alt={project.name}
                />
            </div>
        );
    });

    return (
        <>
            <Helmet>
                <title>ClearServices - General Contracting & Janitorial Services</title>
                <meta
                    name='description'
                    content='We provide professional contracting and janitorial services with our experienced crew since July 2017.'
                />
                <link rel='canonical' href='/' />
            </Helmet>
            <Header />
            <Stories data={StoryData.filter((s) => s.highlight === true)} />

            <div id='About' className={styles.sectionContainer}>
                <h1>About Us</h1>
                <p>
                    ClearServices is dedicated to delivering top-notch cleaning and renovation
                    services at reasonable prices. Our mission is to provide outstanding services
                    that meet your high standards. We take pride in our work and strive to make your
                    experience with us enjoyable. We genuinely care about each client and follow up
                    after every service to ensure your satisfaction. You can trust us to be reliable
                    and always deliver on our promises. Our team of experienced professionals has
                    been serving clients for over five years with the necessary expertise in their
                    respective fields. We work exclusively with skilled workers who bring their
                    professional knowledge to every project. ClearServices has been serving a
                    diverse clientele since July 2017.
                </p>
                <div className={styles.aboutImages}>
                    <img src={Trushield} alt='Trushield' />
                    We provide up to $5,000,000 in TruShield insurance coverage to protect your home
                    or business against any potential damages that may occur during our services.
                    <br />
                    <br />
                    All team members undergo a screening process that includes a check of their
                    criminal records and references.
                    <img src={Wsib} alt='Wsib' />
                </div>
            </div>

            <div id='Services' className={styles.serviceWrapper}>
                <img src={ClearCleaning} alt='ClearCleaning' />
                <div className={styles.serviceContainer}>
                    {JanitorialServices}
                    <a
                        href='https://kingautobooth.com'
                        target='_blank'
                        rel='noreferrer'
                    >
                        <ServiceCard
                            className={styles.carWashCard}
                            key='99'
                            icon='carwash.png'
                            serviceName='Auto Detailing'
                            quoteAvailable={false}
                        />
                    </a>
                </div>
            </div>

            <div className={styles.serviceWrapper}>
                <img src={ClearContracting} alt='ClearContracting' />
                <div className={styles.serviceContainer}>{ContractingServices}</div>
            </div>

            <div id='Clients' className={styles.sectionContainer}>
                <h1>Our Clients</h1>
                <div className={styles.clientCarousel}>
                    <div className={styles.carouselContainer}>{ClientRender}</div>
                </div>
            </div>
            <div className={styles.projects}>{ProjectRender}</div>
            <Footer />
        </>
    );
};

export default Home;
